import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import Lottie from "react-lottie"
import lottie3 from "../lotties/lottie03.json"

const lottie3Options = {
  renderer: 'svg',
  loop: true,
  autoplay: true,
  animationData: lottie3,
};

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" noindex={true} />
    <section className="error">
        <Lottie 
          options={lottie3Options}
          id="lottie03"
        />
        <h1>404</h1>
        <h2>Táto stránka pravdepodobne neexistuje.</h2>
        <Link className="contact-button" to="/">Vrátiť sa späť na domovskú stránku</Link>
    </section>
  </Layout>
)

export default NotFoundPage
